import React from "react";
import { Box, Card, Typography } from "@mui/material";
import SecurityIcon from "@mui/icons-material/Security";

const MAIN_COLOR = "#1975D2";
const TEXT_COLOR = "#3E3E3E";

const EncryptionCard = () => {
  return (
    <Box className="mt-8">
      <h3 className="text-2xl text-black font-semibold mb-4 flex items-center">
        Compare AI Models Securely and Seamlessly
        <SecurityIcon className="ml-3 text-green-600" fontSize="large" />
      </h3>
      <Card
        sx={{
          boxShadow: 1,
          padding: "2em",
        }}
      >
        <Box className="flex items-center">
          <Typography
            variant="body1"
            sx={{
              fontSize: "1.1rem",
              mb: 3,
              color: TEXT_COLOR,
            }}
          >
            At thisorthis.ai, your data privacy is our priority. All your
            prompts and AI responses are fully encrypted at rest, safeguarding
            your information from unauthorized access.
            <br />
            <br />
            <li
              style={{
                fontWeight: 500,
                listStyleType: "disc",
              }}
            >
              All your interactions on thisorthis.ai are protected with top-tier
              security measures.
            </li>
            <li
              style={{
                fontWeight: 500,
                listStyleType: "disc",
              }}
            >
              No third parties can access or analyze your stored prompts and
              responses.
            </li>
            <li
              style={{
                fontWeight: 500,
                listStyleType: "disc",
              }}
            >
              Your data remains yours, always.
            </li>
          </Typography>
        </Box>
      </Card>
    </Box>
  );
};

export default EncryptionCard;
